<template>
  <DefaultLayout :showMonthPicker="true" @searchChange="onSearchChange" :searchValue="searchContent" :headerText="['Zestawienie', 'Umów']">
    <SettledContractsTable :searchValue="searchContent"/>
  </DefaultLayout>
</template>

<script>
  import DefaultLayout from '../layouts/DefaultLayout'
  import SettledContractsTable from '../components/table/SettledContractsTable'
  import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
        searchContent: ''
      }
    },
    components: {
      SettledContractsTable,
      DefaultLayout,
    },
    computed: {
      ...mapGetters('traders', ['getMonth']),
      token() {
        return this.$route.query.token
      }
    },
    methods: {
      onSearchChange(value) {
        this.searchContent = value
      },
      loadContracts() {
        this.$store.dispatch('traders/fetchSettledContracts', {
          token: this.$store.getters['auth/getToken'],
        });
      }
    },
    mounted() {
      this.$store.dispatch('auth/loadToken')
      const token = this.token
      if(token) {
        this.$store.dispatch('auth/setToken', token)
      }
      this.loadContracts()
    },
    watch: {
      getMonth() {
        this.loadContracts()
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
