export const getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  getToken(state) {
    return state.token
  },
  currentUser(state) {
    return state.currentUser
  },
}
