export const mutations = {
  SET_CONTRACTS: (state,contracts)=>{
    state.contracts = contracts;
  },
  SET_ISLOADING: (state,isLoading)=>{
    state.isLoading = isLoading;
  },
  SET_HASFAILED: (state,hasFailed)=>{
    state.hasFailed = hasFailed;
  },
  setSettledContracts(state, payload) {
    state.settledContracts = payload
  },
  setSettledContractsSummary(state, payload) {
    state.settledContractsSummary = payload
  },
  setMonth(state, payload) {
    state.month = payload
  },
  setCategory(state, payload) {
    state.category = payload
  }
}