import {CategoryType} from "@/enums/CategoryType.ts";

export const getters = {
  getContracts: (state) => (searchContent) => {
    return state.contracts.filter(contract => {
      let values = []
      if(state.category === CategoryType.photovoltaics) {
        values = [
          contract.date,
          contract.id,
          contract.name,
          contract.phone,
          contract.power,
          contract.address,
          contract.contractStatus,
          contract.installationOrder,
          contract.designer,
          contract.orderStatus,
          contract.plannedDelivery,
          contract.installationStatus,
          contract.installationDate,
          contract.osdStatus,
          contract.osdDate,
          contract.archived,
          contract.cleanAirStatus,
          contract.cleanAirPaymentSent,
          contract.cleanAirFinancingSent,
          contract.cleanAirPaymentSubmission,
          contract.cleanAirFinancingSubmission,
        ]
      }
      if(state.category === CategoryType.pumps || state.category === CategoryType.boilers) {
        values = [
          contract.date,
          contract.id,
          contract.name,
          contract.phone,
          contract.power,
          contract.address,
          contract.contractStatus,
          contract.installationOrder,
          contract.signedDate,
          contract.orderStatus,
          contract.plannedDelivery,
          contract.installationStatus,
          contract.installationDate,
          contract.archived,
          contract.cleanAirStatus,
          contract.cleanAirPaymentSent,
          contract.cleanAirFinancingSent,
          contract.cleanAirPaymentSubmission,
          contract.cleanAirFinancingSubmission,
        ]
      }

      return values.find(val => {
        if(!val) {
          return false
        }
        return String(val).toLowerCase().includes(searchContent.toLowerCase())
      })
    })
  },
  getLoadingStatus: (state)=>{
    return state.hasFailed ? 'Błąd' : state.isLoading ? 'Ładowanie' : ''
  },
  isLoading(state) {
    return state.isLoading
  },
  getSettledContracts(state) {
    return () => {
      return state.settledContracts
    }
  },
  getSettledContractsSummary(state) {
    return () => {
      return state.settledContractsSummary
    }
  },
  getMonth(state) {
    return state.month
  },
  getCategory(state) {
    return state.category
  }
}
