export const ServiceProblems = {
  INVERTER_PROBLEMS: [
    {
      name: 'Inwerter nie działa',
      id: 40,
    },
    {
      name: 'Błąd w inwerterze',
      id: 39,
    },
  ],
  APP_MONITOR_PROBLEMS: [
    {
      name: 'Problem z aplikacją i monitoringiem',
      id: 41,
    },
  ],
  OTHER_PROBLEMS: [
    {
      name: 'Niska produkcja',
      id: 44,
    },
    {
      name: 'Rachunki za energię elektryczną',
      id: 43,
    },
    {
      name: 'Przeciek dachu',
      id: 42,
    },
  ]
}