<template>
  <DefaultLayout @searchChange="onSearchChange" :searchValue="searchContent" @categoryChange="onCategoryChange" :showCategoryPicker="true">
    <PumpContractsTable :searchValue="searchContent"/>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout'
import PumpContractsTable from '../components/table/pumps/PumpContractsTable.vue'

export default {
  data() {
    return {
      searchContent: ''
    }
  },
  components: {
    PumpContractsTable,
    DefaultLayout,
  },
  computed: {
    token() {
      return this.$route.query.token
    }
  },
  methods: {
    onSearchChange(value) {
      this.searchContent = value
    },
    onCategoryChange() {
      this.fetchContracts()
    },
    fetchContracts() {
      this.$store.dispatch('traders/fetchContracts', {
        token: this.$store.getters['auth/getToken'],
      });
    },
  },
  mounted() {
    this.$store.dispatch('auth/loadToken')
    const token = this.$route.query.token
    if(token) {
      this.$store.dispatch('auth/setToken', token)
    }
    this.fetchContracts()
  }
}
</script>

<style scoped>
  .table-page {
    height: 100%;
    max-height: 100%;
  }

  .token {
    width: 100%;
    text-align: center;
    color: hsl(0, 0%, 50%);
  }

  .color {
    color: #87189D;
    cursor: pointer;
  }
</style>
