<template>
  <div class="default-layout">
    <Header
      :showMonthPicker="showMonthPicker"
      :showCategoryPicker="showCategoryPicker"
      @searchChange="$emit('searchChange', $event.target.value)"
      @categoryChange="$emit('categoryChange')"
      :searchValue="searchValue"
      :headerText="headerText"
    />
    <slot/>
    <div class="protip">
      W razie problemów wyślij nam zgłoszenie <a href="https://forms.gle/QwPiJD27hiZF3QB36" target="_blank">tutaj</a>.
    </div>
  </div>
</template>

<script>
  import Header from '../components/Header'

  export default {
    props: {
      searchValue: {
        type: String,
        required: false,
        default: ''
      },
      showMonthPicker: {
        type: Boolean,
        required: false,
        default: false
      },
      showCategoryPicker: {
        type: Boolean,
        required: false,
        default: false,
      },
      headerText: {
        type: Array,
        required: false,
        default() {
          return ['Moje', 'Umowy']
        }
      }
    },
    components: {
      Header,
    }
  }
</script>

<style scoped lang="scss">
  .default-layout {
    padding: 30px 0;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-gap: 30px;
  }

  .protip {
    text-align: center;
    font-size: 18px;
  }
</style>