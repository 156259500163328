export const statusColors = {
  contract: [
    {
      text: 'Przysłany skan',
      color: 'rgb(128, 128, 128)'
    },
    {
      text: 'Wstępnie zweryfikowana',
      color: 'rgb(162, 93, 220)'
    },
    {
      text: 'Zweryfikowana pozytywnie',
      color: 'rgb(120, 75, 209)'
    },
    {
      text: 'Oczekująca na decyzję kredytową',
      color: 'rgb(102, 204, 255)'
    },
    {
      text: 'Oczekujace na wpłatę zaliczki',
      color: 'rgb(253, 171, 61)'
    },
    {
      text: 'Wydana decyzja kredytowa',
      color: 'rgb(87, 155, 252)'
    },
    {
      text: 'Wysłana umowa kredytowa',
      color: 'rgb(0, 134, 192)'
    },
    {
      text: 'Kompletowana dokumentacja',
      color: 'rgb(156, 211, 38)'
    },
    {
      text: 'Oczekująca na zlecenie',
      color: 'rgb(0, 200, 117)'
    },
    {
      text: 'Zlecona do realizacji',
      color: 'rgb(3, 127, 76)'
    },
    {
      text: 'Zawieszona',
      color: 'rgb(202, 182, 65)'
    },
    {
      text: 'Windykowana',
      color: 'rgb(255, 90, 196)'
    },
    {
      text: 'Rezygnacja',
      color: 'rgb(187, 51, 84)'
    },
    {
      text: 'Anulowana',
      color: 'rgb(226, 68, 92)'
    },
    {
      text: 'Zlecone-old',
      color: 'rgb(51, 51, 51)'
    },
    {
      text: 'Błąd systemowy',
      color: 'rgb(255, 100, 46)'
    },
    {
      text: 'Wpłacona kara umowna',
      color: 'rgb(196, 196, 196)'
    },
    {
      text: 'Wezwanie/KRD/SĄD',
      color: 'rgb(189, 168, 249)'
    },
  ],
  order: [
    {
      text: 'Zlecone do Logistyki',
      color: 'rgb(196, 196, 196)'
    },
    {
      text: 'Zleccone do kompletacji',
      color: 'rgb(253, 171, 61)'
    },
    {
      text: 'Konst-elektr w magazynie',
      color: 'rgb(102, 204, 255)'
    },
    {
      text: 'Towar skompletowany',
      color: 'rgb(0, 134, 192)'
    },
    {
      text: 'Towar wysłany',
      color: 'rgb(156, 211, 38)'
    },
    {
      text: 'Towar dostarczony',
      color: 'rgb(3, 127, 76)'
    },
    {
      text: 'Towar rozpakowany - Rezygnacja',
      color: 'rgb(51, 51, 51)'
    },
    {
      text: 'Towar nie wyjechał - rezygnacja',
      color: 'rgb(255, 21, 138)'
    },
    {
      text: 'Opisany',
      color: 'rgb(202, 182, 65)'
    },
    {
      text: 'Do opisania',
      color: 'rgb(87, 155, 252)'
    },
    {
      text: 'Zaplanowany',
      color: 'rgb(255, 203, 0)'
    },
    {
      text: 'Zlecony',
      color: 'rgb(187, 51, 84)'
    },
    {
      text: 'blank',
      color: 'rgb(154, 173, 189)'
    },
    {
      text: 'Odłożone',
      color: 'rgb(255, 90, 196)'
    },
  ],
  installation: [
    {
      text: 'Zlecony',
      color: 'rgb(196, 196, 196)'
    },
    {
      text: 'Do opisania',
      color: 'rgb(102, 204, 255)'
    },
    {
      text: 'Opisany',
      color: 'rgb(87, 155, 252)'
    },
    {
      text: 'Zaplanowany',
      color: 'rgb(202, 182, 65)'
    },
    {
      text: 'Trwający',
      color: 'rgb(253, 171, 61)'
    },
    {
      text: 'Zamontowane',
      color: 'rgb(3, 127, 76)'
    },
    {
      text: 'Anulowane',
      color: 'rgb(255, 90, 196)'
    },
    {
      text: 'Do wyjaśnienia',
      color: 'rgb(126, 59, 138)'
    },
    {
      text: 'DO DOKOŃCZENIA',
      color: 'rgb(51, 51, 51)'
    },
    {
      text: 'Odłożone',
      color: 'rgb(255, 100, 46)'
    },
  ],
  osd: [
    {
      text: 'W realizacji',
      color: 'rgb(196, 196, 196)'
    },
    {
      text: 'Kompletacja danych',
      color: 'rgb(102, 204, 255)'
    },
    {
      text: 'W przygotowaniu',
      color: 'rgb(253, 171, 61)'
    },
    {
      text: 'Zmiana mocy',
      color: 'rgb(120, 75, 209)'
    },
    {
      text: 'Zgłoszone do OSD',
      color: 'rgb(156, 211, 38)'
    },
    {
      text: 'Anulata',
      color: 'rgb(255, 90, 196)'
    },
    {
      text: 'Zamontowane',
      color: 'rgb(3, 127, 76)'
    },
  ]
}