<template>
  <div class="form-content">
      <div class="form-label">
        Skontaktuj się z nami w sprawie
      </div>
      <div>
        <select v-model="selectedIssue">
          <option v-for="issue in issues" :value="issue.id" :key="issue.id">{{issue.name}}</option>
        </select>
      </div>
    <div class="form-label">
      W czym możemy Ci pomóc?
    </div>
    <div v-for="problem in problems" :key="problem.id">
      <div class="problem-frame" @click="() => submitForm(problem.id)">
        {{problem.name}}
      </div>
    </div>
    <div class="form-label">

    </div>
    <div class="flex-element gap-large">
      <div class="flex-element gap-small">
        <div class="user-label">
          Imię i nazwisko:
        </div>
        <div>
          {{userName}}
        </div>
      </div>
      <div class="flex-element gap-small">
        <div class="user-label">
          Telefon:
        </div>
        <div>
          {{userPhone}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ServiceProblems} from '../enums/ServiceProblems.ts'
export default {
  name: "SubmitForm",
  props: ['userName', 'userPhone'],
  computed: {
    problems() {
      return this.selectedIssue === 13 ? ServiceProblems.INVERTER_PROBLEMS : this.selectedIssue === 14 ? ServiceProblems.APP_MONITOR_PROBLEMS : ServiceProblems.OTHER_PROBLEMS
    }
  },
  data() {
    return {
      issues: [
        {
          name: 'Problem z inwerterem',
          id: 13,
        },
        {
          name: 'Problem z aplikacją i monitoringiem',
          id: 14,
        },
        {
          name: 'Inne',
          id: 15,
        },
      ],
      selectedIssue: 13,
      selectedProblem: 0,
    }
  },
  methods: {
    submitForm(problem) {
      const url = `https://sundaypolska.atlassian.net/servicedesk/customer/portal/3/group/${this.selectedIssue}/create/${problem}`
      const queryParams = `customfield_10044=${this.userName}&customfield_10040=${this.userPhone}`
      window.open(`${url}?${queryParams}`, '_blank')
      this.$emit('close')
    }
  },
}
</script>

<style scoped>
  select {
    font-size: 16px;
    border: none;
    background-color: #edeff2;
    padding: 8px;
  }

  option {
    background-color: white;
  }

  .form-content {
    font-size: 16px;
  }

  .flex-element {
    display: flex;
    align-items: center;
  }

  .gap-small {
    gap: 10px
  }

  .gap-large {
    gap:40px
  }

  .form-label {
    font-weight: 700;
    padding: 20px 0 10px;
  }

  .user-label {
    font-weight: 700;
  }

  .problem-frame {
    padding: 10px 20px;
    border: darkgrey 1px solid;
    margin-bottom: 2px;
    width: 40%;
  }

  .problem-frame:hover {
    background-color: #edeff2;
    cursor: pointer;
  }
</style>
