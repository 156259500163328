<template>
  <div class="empty-table">
    Nie ma tu żadnych danych, spróbuj zmienić filtry lub spróbuj później.
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped lang="scss">
  .empty-table {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 100%;
    font-size: 16px;
    color: hsl(0deg 0% 50%);
    box-sizing: border-box;
    padding: 30px 0;
  }
</style>