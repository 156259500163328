<template>
  <div class="contracts-table">
    <Table :isLoading="isLoading"  :isEmpty="!contracts.length">
      <thead>
        <tr>
          <th v-for="name in tableNames" :key="name">{{name}}</th>
        </tr>
      </thead>
      <template v-if="!isLoading">
        <tbody>
          <tr v-for="contract in contracts" :key="contract.id" :class="{'is-expanded': contract.id === expandedContractId}" @click="toggleExpand(contract.id)">
            <FoldableTd v-for="name in tableNames" :key="name">{{contract.valuesObject[name]}}</FoldableTd>
          </tr>
        </tbody>
      </template>
    </Table>
    <PopUp :show-submit-button="false" header-text="Sunday Serwisy" subheader-text="Złóż wniosek korzystając z opcji dostępnych niżej." @close="toggleShowModal" v-if="showModal">
      <SubmitForm @close="toggleShowModal" :user-name="userName" :user-phone="userPhone" />
    </PopUp>
  </div>
</template>

<script>
import PopUp from "../PopUp";
import SubmitForm from "../SubmitForm";
import FoldableTd from '../FoldableTd'
import Table from './Table'

export default {
  components: {Table, FoldableTd, PopUp, SubmitForm},
  data(){
    return{
      tableNames: ["id", "Name", "Handlowiec", "Manago", "Dyrektor", "Moc", "Współczynnik zaliczenia", "Moc instalacji po korektach", "Podmiot_finansujacy", "Panele (U)", "Model_inwertera", "System_montazowy", "Podpisanie_umowy", "Zlecenie_Montazu", "Kwota brutto z umowy", "Stawka_VAT", "Kwota netto z Umowy", "Kwota brutto z Umowy per kW", "Kwota netto z Umowy per kW", "Cennik brutto bazowy per kW", "Dopłata do paneli brutto per kW", "Dopłata do inwertera brutto per kW", "Dopłata do dachu płaskiego / gruntu [brutto/ kWp]", "Dopłata do przekopu / przewodu [brutto/ kWp]", "Dopłata za TIGO [zł brutto/ kWp]", "Pozostałe dopłaty [brutto / kW]", "Cennik brutto per kW", "Cena netto minimalna z cennika + dopłaty per kW", "Różnica netto w cenie minimalnej  per kW", "Prowizja od nadwyżek", "Stawka prowizyjna [zł / kW]", "Źródło leada", "Miesiąc rozliczenia"],
      expandedContractId: null,
      showModal: false,
      userName: "",
      userPhone: null,
    }
  },
  computed: {
    contracts() {
      return this.$store.getters['traders/getSettledContracts'](this.searchValue) || [];
    },
    isLoading() {
      return this.$store.getters['traders/isLoading'];
    }
  },
  props: {
    searchValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    toggleExpand(id) {
      this.expandedContractId = id
    },
    showSubmitPopup(name, phoneNumber) {
      this.userName = name
      this.userPhone = phoneNumber
      this.toggleShowModal()
    },
    toggleShowModal() {
      this.showModal = !this.showModal
    },
  },
}
</script>

<style scoped lang="scss">
  .contracts-table {
    display: grid;
    box-sizing: border-box;
    grid-gap: 30px;
    height: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: initial;
  }

  .title {
    width: 300px;
    float: left;
  }

  .color {
    color: #87189D;
  }

  button {
    background-color: #87189D;
    color: white;
    border: none;
    padding: 6px 12px;
    margin: 0 4px;
    font-weight: 600;
  }

  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
</style>
