export class ContractAdapter {
  static mapContract(contract) {
      return {
        ...contract,
        name: ContractAdapter.mapName(contract.name),
        id: ContractAdapter.getIdFromName(contract.name)
      }
  }

  static mapName(name) {
    const id = ContractAdapter.getIdFromName(name)
    if(!id) {
      return name
    }
    return name.replace(`[ ${id} ]`, '').trim()
  }

  static getIdFromName(name) {
    const matches = name.match(/^\[ ([0-9]+) \].+$/)
    if(!matches) {
      return null
    }
    if(!matches[1]) {
      return null
    }
    return matches[1]
  }
}