export const mutations = {
  setToken(state, payload) {
    state.token = payload
  },
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload
  },
  setCurrentUser(state, payload) {
    state.currentUser = payload
  },
}
