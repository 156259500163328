import {CategoryType} from '../../enums/CategoryType.ts'

export const state = () => {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)
  return {
    contracts: null,
    isLoading: true,
    hasFailed: true,
    settledContracts: null,
    settledContractsSummary: null,
    month: `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}`,
    category: CategoryType.photovoltaics,
  }
}