<template>
  <td :style="style">
    <div class="foldable-td">
      <slot/>
    </div>
  </td>
</template>

<script>

export default {
  computed: {
    style(){
      const maxWidth = this.maxWidth ? `${this.maxWidth}px` : '100%'
      return `width: ${this.width}px; max-width: ${maxWidth}`
    }
  },
  props:{
    width: String,
    maxWidth: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
  .foldable-td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0 2px;
    max-width: 200px;
  }
</style>
