import UniversalCookie from 'universal-cookie'

export const actions = {
  async setToken({commit}, token) {
    const cookies = new UniversalCookie()
    cookies.set('auth-token', token, {
      path: '/',
    })
    console.log('set token')
    commit('setToken', token)
    commit('setLoggedIn', true)
  },
  async removeToken({commit}) {
    const cookies = new UniversalCookie()
    cookies.remove('auth-token')
    commit('setToken', null)
    commit('setLoggedIn', false)
  },
  async loadToken({commit}) {
    const cookies = new UniversalCookie()
    const token = cookies.get('auth-token')
    if(token) {
      commit('setToken', token)
      commit('setLoggedIn', true)
    }
  },
  async logout({dispatch}) {
    dispatch('removeToken')
  },
}
