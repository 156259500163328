<template>
  <td class="status" :style="style">
    {{realText}}
  </td>
</template>

<script>
  import {statusColors} from './statusColors'

  export default {
    props: {
      text: String,
      type: String,
    },
    computed: {
      colorValue() {
        return statusColors[this.type] && statusColors[this.type].find(e => e.text === this.realText)
      },
      style() {
        if(!this.colorValue) {
          return {
            background: 'hsl(0deg 0% 90%)',
            color: 'hsl(0deg 0% 33%)'
          }
        }
        return {
          background: this.colorValue.color,
          color: 'white'
        }
      },
      realText() {
        return this.text ? this.text.trim().split(', ')[0] : 'Brak statusu'
      }
    }
  }
</script>

<style scoped lang="scss">
  .status {

  }
</style>