<template>
  <div class="header">
    <Center>
      <div class="header-wrapper">
        <div class="left">
          <div class="logo">
            <img src="/img/sunday-logo.png" alt="Sunday Logo"/>
          </div>
          <div class="title">{{headerText[0]}} <span class="color">{{headerText[1]}}</span></div>
        </div>
        <div class="center">
          <div class="links">
            <RouterLink class="link-item" :to="contractsLink">Umowy</RouterLink>
            <RouterLink class="link-item" :to="{name: 'settled-contracts'}">Zestawienie</RouterLink>
            <RouterLink class="link-item" :to="{name: 'settled-contracts-summary'}">Podsumowanie</RouterLink>
          </div>
        </div>
        <div class="quick-actions">
          <select :value="getMonth" @change="onMonthChange" v-if="showMonthPicker">
            <option :value="month" v-for="month in availableMonths" :key="month">{{month}}</option>
          </select>
          <select :value="getCategory" @change="onCategoryChange" v-if="showCategoryPicker">
            <option :value="category" v-for="category in availableCategories" :key="category">{{category}}</option>
          </select>
          <input class="search" @input="$emit('searchChange', $event)" :value="searchValue" placeholder="Szukaj..." type="text">
        </div>
      </div>
    </Center>
  </div>
</template>

<script>
  import Center from './Center'
  import {CategoryType} from '../enums/CategoryType.ts'
  import {mapGetters, mapMutations} from 'vuex'

  export default {
    components: {
      Center
    },
    data() {
      const availableMonths = []
      const date = new Date()
      for(let i = 0; i < 10; i++) {
        date.setMonth(date.getMonth() - 1)
        availableMonths.push(`${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}`)
      }
      return {
        availableMonths,
        availableCategories: [CategoryType.photovoltaics, CategoryType.pumps, CategoryType.boilers]
      }
    },
    props: {
      searchValue: {
        type: String,
        required: true
      },
      headerText: {
        type: Array,
        required: true
      },
      showMonthPicker: {
        type: Boolean,
        required: true
      },
      showCategoryPicker: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      ...mapGetters('traders', ['getMonth','getCategory']),
      contractsLink() {
        switch (this.getCategory) {
          case CategoryType.photovoltaics:
            return {name: 'contracts'}
          case CategoryType.pumps:
            return {name: 'pump-contracts'}
          case CategoryType.boilers:
            return {name: 'boiler-contracts'}
          default:
            return {name: 'contracts'}
        }
      }
    },
    methods: {
      ...mapMutations('traders', ['setMonth','setCategory']),
      onMonthChange(e) {
        this.setMonth(e.target.value)
      },
      onCategoryChange(e) {
        const categoryType = e.target.value
        this.setCategory(categoryType)
        this.$emit('categoryChange')
        this.changeRoute()
      },
      changeRoute() {
        if(this.getCategory === CategoryType.boilers) {
          return this.$router.push({name: 'boiler-contracts'})
        }
        if(this.getCategory === CategoryType.photovoltaics) {
          return this.$router.push({name: 'contracts'})
        }
        return this.$router.push({name: 'pump-contracts'})
      }
    }
  }
</script>

<style scoped lang="scss">

  .logo {
    img {
      height: 48px;
    }
  }
  .header {
    background: white;
    position: sticky;
    top: 0;
  }

  .header-wrapper {
    display: grid;
    justify-content: space-between;
    align-items: center;
    height: 60px;
   grid-template-columns: 240px 1fr 240px;
  }

  .search {
    border: 1px solid rgb(0 0 0 / 15%);
    padding: 6px 12px;
    font-size: 15px;
    outline: none;

    &:focus {
      border-color: rgb(0 0 0 / 25%);
    }
  }

  .color {
    color: #86179d;
    font-weight: 700;
  }
  .title {
    font-size: 18px;
    white-space: nowrap;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .links {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    justify-content: center;
    margin: 0 30px;
  }

  .link-item {
    text-decoration: none;
    font-size: 15px;
    color: hsl(0deg 0% 33%);

    &.router-link-active {
      color: #86179d;
      font-weight: 600;
    }
  }

  .quick-actions {
    display: flex;
    gap: 10px;
  }
</style>
