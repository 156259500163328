<template>
  <div>
    <button :class="buttonClassName" @click="$emit(triggeredEvent)">
      {{buttonText}}
    </button>
  </div>
</template>

<script>
export default {
  name: "PopUpButton",
  props: {
    buttonText: String,
    triggeredEvent: String,
    isPrimary: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    buttonClassName() {
      return this.isPrimary ? 'primary-button' : 'secondary-button'
    }
  }
}
</script>

<style scoped>
  .primary-button {
    background-color: #87189D;
    color: white;
    border: none;
  }

  .secondary-button {
    background-color: white;
    color: #87189D;
    border: 1px #87189D solid;
  }

  button {
    padding: 6px 16px;
    font-size: 16px;
  }

  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
</style>
