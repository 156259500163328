<template>
  <div class="contracts-table">
    <Table :isLoading="isLoading" :isEmpty="!contracts.length">
      <thead>
      <tr>
        <th v-for="name in tableNames" :key="name">{{name}}</th>
      </tr>
      </thead>
      <template v-if="!isLoading">
        <tbody>
        <tr v-for="contract in contracts" :key="contract.id" :class="{'is-expanded': contract.id === expandedContractId}" @click="toggleExpand(contract.id)">
          <FoldableTd width="50" maxWidth="200" style="text-align: left;">[{{contract.id}}] {{contract.name}}</FoldableTd>
          <FoldableTd width="70">{{contract.date}}</FoldableTd>
          <FoldableTd width="200">{{contract.address}}</FoldableTd>
          <FoldableTd width="50">{{contract.phone}}</FoldableTd>
          <FoldableTd width="30">{{contract.power}}</FoldableTd>
          <Status :text="contract.contractStatus" type="contract"/>
          <FoldableTd width="80">{{contract.installationOrder}}</FoldableTd>
          <FoldableTd width="50">{{contract.designer}}</FoldableTd>
          <Status :text="contract.orderStatus" type="order"/>
          <FoldableTd width="50">{{contract.plannedDelivery}}</FoldableTd>
          <Status :text="contract.installationStatus" type="installation"/>
          <FoldableTd width="80">{{contract.installationDate}}</FoldableTd>
          <Status :text="contract.osdStatus" type="osd"/>
          <FoldableTd width="80">{{contract.osdDate}}</FoldableTd>
          <FoldableTd width="80">{{contract.archived}}</FoldableTd>
          <FoldableTd width="80">{{contract.cleanAirStatus}}</FoldableTd>
          <FoldableTd width="80">{{contract.cleanAirPaymentSent}}</FoldableTd>
          <FoldableTd width="80">{{contract.cleanAirFinancingSent}}</FoldableTd>
          <FoldableTd width="80">{{contract.cleanAirPaymentSubmission}}</FoldableTd>
          <FoldableTd width="80">{{contract.cleanAirFinancingSubmission}}</FoldableTd>
          <td class="no-padding"><button @click="() => showSubmitPopup(contract.name, contract.phone)">Złóż wniosek</button></td>
        </tr>
        </tbody>
      </template>
    </Table>
    <PopUp :show-submit-button="false" header-text="Sunday Serwisy" subheader-text="Złóż wniosek korzystając z opcji dostępnych niżej." @close="toggleShowModal" v-if="showModal">
      <SubmitForm @close="toggleShowModal" :user-name="userName" :user-phone="userPhone" />
    </PopUp>
  </div>
</template>

<script>
import PopUp from "../PopUp";
import SubmitForm from "../SubmitForm";
import Status from '../Status/Status'
import FoldableTd from '../FoldableTd'
import Table from './Table'

export default {
  components: {Table, FoldableTd, Status, PopUp, SubmitForm},
  data(){
    return{
      tableNames: [
        'Klient',
        'Data utworzenia',
        'Adres',
        'Telefon',
        'Moc (kW)',
        'Status umowy',
        'Zlecenie montażu do DR',
        'Projektant',
        'Status zamówienia',
        'Planowana dostawa',
        'Status montażu',
        'Data montażu',
        'Status OSD',
        'Data zgłoszenia do OSD',
        'Zarchiwizowane',
        'Status czyste powietrze',
        'Data wysłania wniosku o płatność',
        'Data wysłania wniosku o dofinansowanie',
        'Data żłożenia wniosku o płatność',
        'Data żłożenia wniosku o dofinansowanie',
        'Wniosek',
      ],
      expandedContractId: null,
      showModal: false,
      userName: "",
      userPhone: null,
    }
  },
  computed: {
    contracts() {
      return this.$store.getters['traders/getContracts'](this.searchValue) || [];
    },
    isLoading() {
      return this.$store.getters['traders/isLoading'];
    }
  },
  props: {
    searchValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    toggleExpand(id) {
      this.expandedContractId = id
    },
    showSubmitPopup(name, phoneNumber) {
      this.userName = name
      this.userPhone = phoneNumber
      this.toggleShowModal()
    },
    toggleShowModal() {
      this.showModal = !this.showModal
    },
  },
}
</script>

<style scoped lang="scss">
  .contracts-table {
    display: grid;
    box-sizing: border-box;
    grid-gap: 30px;
    height: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: initial;
  }

  .title {
    width: 300px;
    float: left;
  }

  .color {
    color: #87189D;
  }

  button {
    background-color: #87189D;
    color: white;
    border: none;
    padding: 6px 12px;
    margin: 0 4px;
    font-weight: 600;
  }

  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
</style>
