<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
              {{headerText}}
          </div>
          <div class="modal-subheader">
            {{subheaderText}}
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div class="modal-buttons">
            <PopUpButton
              v-if="showCloseButton"
              @close="$emit('close')"
              :button-text="closeButtonText"
              :triggered-event="'close'"
            />
            <PopUpButton
              v-if="showCustomButton"
              @custom="$emit('custom')"
              :button-text="customButtonText"
              :triggered-event="'custom'"
              :is-primary="true"
            />
            <PopUpButton
              v-if="showSubmitButton"
              @submit="$emit('submit')"
              :button-text="submitButtonText"
              :triggered-event="'submit'"
              :is-primary="true"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PopUpButton from './PopUpButton'
export default {
  name: "PopUp",
  components: {PopUpButton},
  props: {
    headerText: String,
    subheaderText: String,
    closeButtonText: {
      type: String,
      default: 'Anuluj',
    },
    customButtonText: {
      type: String,
      default: 'Inne...',
    },
    submitButtonText: {
      type: String,
      default: 'Wyślij',
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showCustomButton: {
      type: Boolean,
      default: false
    },
    showSubmitButton: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    position: absolute;
    top:100px
  }

  .modal-container {
    width: 750px;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header {
    margin-top: 0;
    color: #87189D;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 12px;
  }

  .modal-subheader {
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;
  }

  .modal-body {
    padding-bottom: 16px;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap:10px
  }
</style>
