<template>
  <div class="table">
    <div class="loading-wrapper" v-if="isLoading">
      <Loading/>
    </div>
    <EmptyTable v-else-if="isEmpty"/>
    <table v-else>
      <slot/>
    </table>
  </div>
</template>

<script>
  import Loading from '../Loading'
  import EmptyTable from '../EmptyTable'
  export default {
    components: {EmptyTable, Loading},
    props: {
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      isEmpty: {
        type: Boolean,
        required: false,
        default: false,
      }
    }
  }
</script>


<style scoped lang="scss">
  .table {
    overflow-x: auto;
    overflow-y: initial;
    padding-left: 30px;
    max-height: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
  th {
    padding: 4px 4px;
    text-align: center;
    background: #33058c;
    color: #f2f2f2;

    &:first-child {
      position: sticky;
      left: -30px;
    }
  }

  td {
    padding: 8px 4px;
    text-align: center;
    border: 1px solid hsl(0 0% 0% / 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.no-padding {
      padding: 0;
    }
  }

  td:first-child {
    position: sticky;
    left: -30px;
    background: white;
    font-weight: 600;
    box-shadow: inset -2px 0px 0px hsl(0deg 0% 66%);
  }

  tr {
    &:nth-child(2n) {
      background: hsl(0deg 0% 98%);
      & td:first-child {
        background: hsl(0deg 0% 98%);
      }
    }
    &.is-expanded {

      & td {
        white-space: normal;
        word-break: break-word;
      }

      & ::v-deep .foldable-td {
        white-space: normal;
        word-break: break-word;
      }

      //& .expa
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  .loading-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>