<template>
  <div class="index">
    <div class="content">
      <h1>Wpisz token uwierzytelniający:</h1>
      <label>
        <input type="text" class="textbox" placeholder="Token" v-model="token" autofocus @keyup.enter="navigate">
      </label>
      <router-link :to="link">
        <button class="button">Przejdź do swoich statusów</button>
      </router-link>
    </div>
    <Logo/>
  </div>
</template>

<script>
  import Logo from '../components/Logo'

  export default {
    data() {
      return {
        token: '',
      }
    },
    computed: {
      link() {
        return {
          name: 'contracts',
          query: {
            token: this.token
          }
        }
      }
    },
    methods: {
      navigate() {
        this.$router.push(this.link)
      }
    },
    components: {
      Logo,
    }
  }
</script>

<style>
#app, body, html {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>

<style lang="scss" scoped>
  .index {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100%;
    height: 100%;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    //font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .textbox {
    padding: 10px 12px;
    font-size: 16px;
    border: 1px solid hsl(0 0% 0% / 0.3);
    outline: none;
    margin: 15px 0;
    transition: all .1s ease;
    text-align: center;

    &:focus {
      border-color: hsl(0 0% 0% / 0.33);
    }
  }

  .button {
    color: #86179d;
    font-size: 18px;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    outline: none;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
</style>