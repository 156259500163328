import Vue from 'vue'
import Vuex from 'vuex'
import App from './components/App.vue'
import modules from './store'
import VueRouter from 'vue-router'
import IndexPage from './pages/Index'
import ContractsPage from './pages/ContractsPage'
import SettledContractsPage from './pages/SettledContractsPage'
import SettledContractsSummaryPage from './pages/SettledContractsSummaryPage'
import PumpContractsPage from "@/pages/PumpContractsPage";
import BoilerContractsPage from './pages/BoilerContractsPage'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules
})
Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    {path: '/', component: IndexPage},
    {path: '/pump-table', component: PumpContractsPage, name: 'pump-contracts'},
    {path: '/boiler-table', component: BoilerContractsPage, name: 'boiler-contracts'},
    {path: '/table', component: ContractsPage, name: 'contracts'},
    {path: '/settled-contracts', component: SettledContractsPage, name: 'settled-contracts'},
    {path: '/settled-contracts-summary', component: SettledContractsSummaryPage, name: 'settled-contracts-summary'},
  ],
  mode: 'history',
})
Vue.use(VueRouter)

new Vue({
  store,
  render: h => h(App),
  router,
}).$mount('#app')
