<template>
  <div id="app">
    <RouterView/>
  </div>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>
  body, input, button, select, textarea {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.2px;
    color: hsla(0, 0%, 10%, 1);
    font-size: 13px;
  }
</style>
