import axios from 'axios'
import {ContractAdapter} from '../../services/ContractAdapter'

export const actions = {
  async fetchContracts({commit, getters}, {token}){
    commit('SET_HASFAILED', false)
    commit('SET_ISLOADING', true)
    try {
      const res = await axios.get(process.env.VUE_APP_API_HOST + '/contracts', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          category: getters.getCategory
        }
      })
      commit('SET_ISLOADING', false)
      commit('SET_CONTRACTS', res.data.data.contracts.map(contract => {
        return ContractAdapter.mapContract(contract)
      }))
    } catch(e) {
      console.error(e)
      commit('SET_HASFAILED', true)
    }
  },
  async fetchSettledContracts({commit, getters}, {token}){
    commit('SET_HASFAILED', false)
    commit('SET_ISLOADING', true)
    try {
      const res = await axios.get(process.env.VUE_APP_API_HOST + '/client-status/settled-contracts', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          period: getters.getMonth
        }
      })
      commit('SET_ISLOADING', false)
      commit('setSettledContracts', res.data.data.contracts)
    } catch(e) {
      console.error(e)
      commit('SET_HASFAILED', true)
    }
  },
  async fetchSettledContractsSummary({commit, getters}, {token}){
    commit('SET_HASFAILED', false)
    commit('SET_ISLOADING', true)
    try {
      const res = await axios.get(process.env.VUE_APP_API_HOST + '/client-status/settled-contracts-summary', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          period: getters.getMonth
        }
      })
      commit('SET_ISLOADING', false)
      commit('setSettledContracts', res.data.data.contracts)
    } catch(e) {
      console.error(e)
      commit('SET_HASFAILED', true)
    }
  },

}